$(document).ready(function() {
    // プラン選択画面と月額プラン変更画面
    const buy_plans = [
        {
            'amount_el': '#monthly_amount',
            'checkbox_el': '.monthly-checkbox input.p-checkboxOptionsSelect__input',
            'amount': 550,
            'grant_expiration_month': 1,
            'amount_text': '\n@@\n\n<span class=\"p-planSelectForm__price-unit\">／月\n</span>',
            'confirm_plan_el': '#confirm_monthly_plan',
            'modal_amount_el': '.modal-monthly-amount',
            'modal_options_el': '#modal_monthly_options'
        },
        {
            'amount_el': '#three_months_amount',
            'checkbox_el': '.three-months-checkbox input.p-checkboxOptionsSelect__input',
            'amount': 1650,
            'grant_expiration_month': 3,
            'amount_text': '\n@@\n',
            'confirm_plan_el': '#confirm_three_months_plan',
            'modal_amount_el': '#modal_three_months_amount',
            'modal_options_el': '#modal_three_months_options'
        },
        {
            'amount_el': '#half_year_amount',
            'checkbox_el': '.half-year-checkbox input.p-checkboxOptionsSelect__input',
            'amount': 2750,
            'grant_expiration_month': 6,
            'amount_text': '\n@@\n',
            'confirm_plan_el': '#confirm_half_year_plan',
            'modal_amount_el': '#modal_half_year_amount',
            'modal_options_el': '#modal_half_year_options'
        },
        {
            'amount_el': '#year_amount',
            'checkbox_el': '.year-checkbox input.p-checkboxOptionsSelect__input',
            'amount': 4950,
            'grant_expiration_month': 12,
            'amount_text': '\n@@\n',
            'confirm_plan_el': '#confirm_year_plan',
            'modal_amount_el': '#modal_year_amount',
            'modal_options_el': '#modal_year_options'
        },
        {
            'amount_el': '#next_monthly_amount',
            'checkbox_el': '.next-monthly-checkbox input.p-checkboxOptionsSelect__input',
            'amount': 550,
            'grant_expiration_month': 1,
            'amount_text': '¥@@'
        }
    ];

    $.each(buy_plans, function(_, buy_plan){
        const checkbox_el = $(buy_plan['checkbox_el']);
        if (checkbox_el) {
            let amount = buy_plan['amount'];
            // ページ読み込み時に設定
            const amount_el = $(buy_plan['amount_el']);
            const grant_expiration_month = buy_plan['grant_expiration_month'];
            const changed_amount = changeAmount(amount, grant_expiration_month, checkbox_el);
            const amount_text = buy_plan['amount_text'].replace('@@', changed_amount);
            const confirm_plan_el = $(buy_plan['confirm_plan_el']);
            const modal_amount_el = $(buy_plan['modal_amount_el']);
            const modal_options_el = $(buy_plan['modal_options_el']);
            amount_el.empty();
            amount_el.append(amount_text);
            $.each(checkbox_el, function(_, element){ changeActiveClass(element) });

            // チェックボックス変更時に設定
            checkbox_el.change(function() {
                const changed_amount = changeAmount(amount, grant_expiration_month, checkbox_el);
                const amount_text = buy_plan['amount_text'].replace('@@', changed_amount);
                amount_el.empty();
                amount_el.append(amount_text);
                // モーダルの方の金額も更新
                modal_amount_el.text(changed_amount.concat('円'));
                changeActiveClass(this);
            });

            // 申込ボタンがクリックされた時モーダルのオプション表示を更新
            confirm_plan_el.click(function() {
                modal_options_el.empty();
                checkbox_el.each(function(index) {
                    if ($(this).is(':checked')) {
                        var option_detail = $('#option' + index + '_detail').clone();
                        option_detail.find('#option_quantity').text(grant_expiration_month);
                        let option_amount = 50 * grant_expiration_month;
                        option_detail.find('#option_amount').text(option_amount.toString().concat('円'));
            
                        modal_options_el.append(option_detail);
                    }
                });
            });
        }
    });

    // チェックボックスの状態から、基準金額を元にオプションを加算・減算した金額を計算する
    function changeAmount (base_mount, month, element) {
        element.each(function () {
            if (this.checked) {
                base_mount += 50 * month;
            }
        })
        return base_mount.toLocaleString();
    }

    // チェックボックスの状態から、背景色をコントロールする
    function changeActiveClass (element) {
        if (element.checked) {
            $(element).parents('.js-options-item').addClass('is-active');
        } else {
            $(element).parents('.js-options-item').removeClass('is-active');
        }
    }

    // オプションプランの詳細開閉ボタンのクラスを付与・削除
    const optionDetailOpenButton = $('.js-options-detail-open-button');
    if (optionDetailOpenButton) {
        optionDetailOpenButton.on('click', function() {
            const detailId = $(this).attr('data-target')
            if ($(detailId).hasClass('show')) {
                $(this).removeClass('is-open');
            } else if ($(detailId).hasClass('collapsing')) {
                // 詳細開閉中、この間は何も処理しない
            } else {
                $(this).addClass('is-open');
            }
        });
    }
})
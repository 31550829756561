$(document).ready(function() {
  function showDatetime(){
    var today = new Date();
    year = today.getFullYear();
    month = today.getMonth() + 1;
    date = today.getDate();
    hour = ('0' + today.getHours()).slice(-2);
    minute = ('0' + today.getMinutes()).slice(-2);
    second = ('0' + today.getSeconds()).slice(-2);

    $('#datetime').html(`${year}年${month}月${date}日 ${hour}:${minute}:${second}`);
  }
  if($("#datetime").length != 0){
    showDatetime();
    setInterval(showDatetime,1000);
  }
});

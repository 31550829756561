// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('bootstrap')
require ('@fortawesome/fontawesome-free/js/all')

require("custom/clipboard")
require("custom/coupon_used_histories")
require("custom/map")
require("custom/disable_browser_back")
require("custom/agreement")
require("custom/drinking_area")
require("custom/sb_payment")
require("custom/disable_enter_to_submit_form")
require("custom/plan_select")
require("custom/option_select")

import '../stylesheets/application'

require.context('images', true, /\.(png|svg)$/);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
